// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthProvider } from './Auth';
import LoginScreen from './screens/LoginScreen';
import OTPScreen from './screens/OTPScreen';
import HomeScreen from './screens/HomeScreen';
import { VerificationProvider } from './VerificationContext';
function App() {
  return (
    <AuthProvider>
      <VerificationProvider>
        <Router basename="/">
        <Switch>
          <Route path="/login" component={LoginScreen} />
          <Route path="/otp" component={OTPScreen} />
          {/* Add more routes for your application */}
          <Route exact path="/" component={HomeScreen} />
        </Switch>
      </Router>
      </VerificationProvider>
    </AuthProvider>
  );
}

export default App;