import React from "react";
import { Grid, CircularProgress, Box } from "@material-ui/core";
const LoadingScreen = () => {
  return (
    <Grid
      container
      spacing={5}
      alignItems="center"
      justify="center"
      style={{
        padding: "20px",
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress color="primary" />
      </Box>
    </Grid>
  );
};

export default LoadingScreen;
