import React , {useState}from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FolderIcon from "@material-ui/icons/Folder";
import GetAppIcon from "@material-ui/icons/GetApp";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import StarBorder from "@material-ui/icons/StarBorder";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Divider from '@material-ui/core/Divider';
import moment from "moment";
import { Box, Tooltip } from "@material-ui/core";
var fileDownload = require('js-file-download');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default function PendingList({
  date_time,
  user_name,
  log_id,
  log_file,
  status,
  note,
  isSubmit,
  setSubmit,
  setFileId,
  setFileName,
  toggleApproveDialog,
  toggleRejectDialog,
  appDisabled,
  setAppDisabled
}) {
  const classes = useStyles();
  const [dense, setDense] = useState(false);
  const [secondary, setSecondary] = useState(false);  
  const downloadFile = async (file_path) => {
    setAppDisabled(true);
    let file_name = file_path.split("/").pop(); 
    const access_token = localStorage.getItem("access_token"); 
    
    const config = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + access_token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({file_id: log_id}), 
    };

    const response = await fetch(`https://etd-means.aboitizpower.com/means/download`, config);
    const data = await response.text();
    fileDownload(data, file_name);
    setAppDisabled(false);
  };  
  const approveAlert = (id, file) => {
    setAppDisabled(true);
    setFileId(id);
    setFileName(file);
    toggleApproveDialog(true);

  }
  const rejectAlert = (id, file) => {
    setAppDisabled(true);
    setFileId(id);
    setFileName(file);
    toggleRejectDialog(true);
  }
  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
            primary={<>File: <b>{log_file}</b> </>}
            secondary={"User: " + user_name}
        /> 
        <ListItemSecondaryAction>
        <Tooltip title="Download">
          <IconButton edge="end" onClick={() => downloadFile(log_file)} download>
            <GetAppIcon />
          </IconButton>
          </Tooltip>
          <Tooltip title="Approve">
          <IconButton disabled={appDisabled} edge="end" onClick={() => approveAlert(log_id, log_file)} download>
            <CheckCircleIcon />
          </IconButton>
          </Tooltip>
          <Tooltip title="Reject">
          <IconButton disabled={appDisabled} edge="end" onClick={() => rejectAlert(log_id,log_file)} download>
            <CancelIcon />
          </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider light />
     
    </>
  );
}
