import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ApproveDialog({open, toggle, log_id, log_file, setSubmit, isSubmit, setSnackOpen, setSeverity, setApproverResponse, setReload, reload, appDisabled, setAppDisabled}) {
    const approveSched = async () => {
        
        const access_token = localStorage.getItem('access_token');
        let customer = localStorage.getItem('userCust');
        const config = {
          method: "POST",
          headers: {
            Authorization: "Bearer " + access_token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({schedId: log_id, customer, user_id: localStorage.getItem('userId')}), 
        };
        const response = await fetch(
          'https://etd-means.aboitizpower.com/means/approve', config
        );
        const data = await response.json();
        if (data.message == "Transaction Completed") {
          
          setApproverResponse("Success");
          setSeverity("success");
          setSnackOpen(true);
          setReload(!reload);
          setAppDisabled(false)
          toggle(false);
        }else {
          setApproverResponse("Failed");
          setSeverity("error");
          setSnackOpen(true);
          setAppDisabled(false)
          setReload(!reload);
          toggle(false);
        }
        setAppDisabled(false)
        setSubmit(!isSubmit);
      };

  return (
    <> 
      <Dialog
        open={open}
        onClose={() => toggle(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Approve {log_file} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggle(false)} color="primary">
            No
          </Button>
          <Button onClick={approveSched} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}