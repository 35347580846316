 
import React, { createContext, useContext, useEffect, useState } from 'react';

const VerificationContext = createContext();

export function useVerification() {
  return useContext(VerificationContext);
}

export function VerificationProvider({ children }) {
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  useEffect(async() => {
    // console.log(localStorage.getItem('access_token'))
    if(localStorage.getItem('access_token') != null){
    // console.log(localStorage.getItem('access_token'))
    const access_token = localStorage.getItem("access_token"); 
    const config = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + access_token,
        'Content-Type': 'application/json'
      }
    };

    const response = await fetch("https://etd-means.aboitizpower.com/means/getUserLoginInfo", config);
    const data = await(await response.json()).data;
    // console.log(data) 
    localStorage.setItem('userName', data.user_name);
    localStorage.setItem('userEmail', data.user_email); 
    localStorage.setItem('userRole', data.user_role);
    localStorage.setItem('userCust', data.user_cust);
    localStorage.setItem('userId', String(data.user_id));
    // console.log(data); 
    setIsEmailVerified(true)
    }
  },[])
  return (
    <VerificationContext.Provider value={{ isEmailVerified, setIsEmailVerified }}>
      {children}
    </VerificationContext.Provider>
  );
}