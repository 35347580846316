import React, { useEffect, useState } from 'react';
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { forwardRef } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from "@material-ui/lab/Alert";
import Paper from '@material-ui/core/Paper';
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file 
import FileDialog from "../dialogs/FileUploadBCQDialog";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from '@material-ui/core';
import { CsvBuilder } from 'filefy';
var fileDownload = require('js-file-download');


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
const useStyles = makeStyles((theme) => ({
    Paper: {
        padding: 2,
        margin: theme.spacing(1),
        flex: 1,
        alignItems: "center",
    },
    Table: {
        borderRadius: 10,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "yellow"
    },
    speedDial: {
        margin: theme.spacing.unit,
        position: "fixed",
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 3
      },
    backdrop: {
    zIndex: 100,
    color: "#fff",
  },
    formControl: {
        margin: theme.spacing(1),
        fontSize: 5,
        minWidth: 250,
        maxWidth: 350
    }
}));
export default function AcknowledgedScreen() {
    const [isFileDialogOpen, toggleFileDialog] = useState(false);
    const [tableData, getTableData] = useState([]);
    const [columnData, getColumnData] = useState([]);
    const [logId, setLogId] = useState('');
    const [fileName, setFileName] = useState('');
    const [open, setOpen] = useState(false);
    const [appDisabled, setAppDisabled] = useState(false);
    const [type, setType] = useState(false);
    const classes = useStyles();
    const [regions, setRegions] = useState();
    const [customers, setCustomers] = useState();
    const [selectedRegion, setSelectedRegion] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [severity, setSeverity] = useState('');
    const [severity2, setSeverity2] = useState('');
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackOpen2, setSnackOpen2] = React.useState(false);
    const [isSubmit, setSubmit] = useState(false);
    const [direction, setDirection] = React.useState("up");
    const [approverResponse, setApproverResponse] = useState('');
    const [uploadResponse, setUploadResponse] = useState('');
    const [reload, setReload] = useState(false); 
    const [selectedBbid, setSelectedBbid] = useState();
    const [bbidList, setBbidList] = useState();
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    
    const snackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackOpen(false);
    };
    const snackbarClose2 = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackOpen2(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [dateRange, setDateRange] = useState(new Date()); 
    const getRegions = async () => {
        const access_token = localStorage.getItem('access_token');
        const config = {
            headers: {
                'Authorization': 'Bearer ' + access_token,
            }
        }
        const response = await fetch(`https://etd-means.aboitizpower.com/means/regionsList`, config);
        const data = await response.json();
        console.log(data)
        setRegions(data.data)
    }
    const getCustomers = async () => {
        const access_token = localStorage.getItem('access_token');
        const config = {
            headers: {
                'Authorization': 'Bearer ' + access_token,
            }
        }
        const response = await fetch(`https://etd-means.aboitizpower.com/means/customersListReg/${selectedRegion}`, config);
        const data = await response.json();
        setCustomers(data.data)
    }
    const getBbid = async () => {
        const access_token = localStorage.getItem('access_token');
        const config = {
            method: "POST",
            headers: {
                Authorization: "Bearer " + access_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ customer: selectedCustomer, date_range:dateRange }),
        };
        const response = await fetch(`https://etd-means.aboitizpower.com/means/bbid`, config);
        const data = await response.json();
        setBbidList(data.data)
    }
 
   const getData = async () => {
    const access_token = localStorage.getItem('access_token');
    const config = {
        method: "POST",
        headers: {
            Authorization: "Bearer " + access_token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ customer: selectedCustomer, date_range:dateRange, bbid: selectedBbid }),
    };
    const response = await fetch(`https://etd-means.aboitizpower.com/means/getConsolidatedData`, config);
    const data = await response.json();
    getColumnData(data.column);
    getTableData(data.response);
    
   }
    const downloadFile = async () => {
        if (logId) {
            const access_token = localStorage.getItem("access_token");
            const config = {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + access_token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ file_id: logId }),
            };

            const response = await fetch(`https://etd-means.aboitizpower.com/means/download`, config);
            const data = await response.text();

            fileDownload(data, fileName);
            setOpen(false);
        }
    };
    const handleDownload = async (v) => {
        setOpen(true);
        setLogId(v.idsubmissions);
        setFileName(v.file_name)
    }
    useEffect(() => {
        if (!isNaN(logId)) {
            downloadFile();
        }
    }, [logId])

    useEffect(() => {
        console.log(dateRange)
        getRegions();
    }, [dateRange])
    useEffect(() => {
        getCustomers();
    }, [selectedRegion])
    useEffect(() => {
        getBbid();
    }, [selectedCustomer])
    useEffect(() => {
        getData();
    }, [selectedBbid, dateRange])


    const handleExportCsv = (toolbar, columns, renderData) => {
        const csvColumns = columns
          .filter(columnDef => {
            return !columnDef.hidden && columnDef.field && columnDef.export !== false; 
          });
    
        const data = renderData.map(rowData =>
          csvColumns.map(columnDef => rowData[columnDef.field])
        );
    
        const builder = new CsvBuilder(('foo') + '.csv')
          .setDelimeter(',')
          .setColumns(csvColumns.map(columnDef => columnDef.title))
          .addRows(data)
          .exportFile();
    
        toolbar.setState({ exportButtonAnchorEl: null }); // this is the reason to pass the m-table-toolbar object
      }

      

    return (
        <Box> 
                    <Box m={1} display="flex" alignItems="flex-start" flexDirection="column" flexWrap="wrap"  >
                        <Card>
                        <Box component={Paper} m={1} display="flex" alignItems="center" flexDirection="row" flexWrap="wrap" >
                            <Box>
                                <FormControl variant="outlined">
                                <DatePicker 
                                    selected={dateRange}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    customInput={<TextField variant='outlined' label="Date" margin="dense" className={classes.formControl}/>}
                                    withPortal
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl margin="dense" variant="outlined" className={classes.formControl}>
                                    <InputLabel>REGION</InputLabel>
                                    <Select
                                        dense
                                        value={selectedRegion}
                                        onChange={(e) => setSelectedRegion(e.target.value)}
                                        label="CUSTOMER"
                                    >
                                        {
                                            regions ? regions.map(x => (
                                                <MenuItem value={x.idregions}>{x.region_name}</MenuItem>
                                            )

                                            ) : null
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl margin="dense" variant="outlined" className={classes.formControl}>
                                    <InputLabel>CUSTOMER</InputLabel>
                                    <Select
                                        value={selectedCustomer}
                                        onChange={(e) => setSelectedCustomer(e.target.value)}
                                        label="CUSTOMER"
                                    >
                                        {
                                            customers ? customers.map(x => (
                                                <MenuItem value={x.idcustomers}>{x.customer_name}</MenuItem>
                                            )

                                            ) : null
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl margin="dense" variant="outlined" className={classes.formControl}>
                                    <InputLabel>BUYER BILLING ID.</InputLabel>
                                    <Select
                                        value={selectedBbid}
                                        onChange={(e) => setSelectedBbid(e.target.value)}
                                        label="BUYER BILLING ID."
                                    >
                                        {
                                            bbidList ? bbidList.map(x => (
                                                <MenuItem value={x.buyer_billing_id}>{x.buyer_billing_id}</MenuItem>
                                            )

                                            ) : null
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box m={3} >
                            <TableContainer component={Table} className={classes.Table}>
                             {tableData.length>0 ? 
                                (   <MaterialTable
                                    icons={tableIcons}
                                    columns={columnData}
                                    data={tableData} 
                                    title={selectedBbid}
                                    options={{ 
                                        exportAllData: true,
                                        columnsButton: true,
                                        exportButton: true, 
                                        pageSize:12, 
                                        padding: "dense", 
                                        headerStyle: {
                                            textAlign: "middle", 
                                            fontWeight: 800,
                                            fontSize: 11
                                        },
                                        cellStyle: {
                                            textAlign: "middle", 
                                            fontSize: 11,
                                            fontWeight: 300,
                                        }, 
                                        actionsColumnIndex: -1
                                    }}

                                />): 'No Data'
                             }
                            </TableContainer>
                        </Box>
                        </Card>
                    </Box>
 
            <FileDialog open={isFileDialogOpen} toggle={toggleFileDialog} setUploadResponse={setUploadResponse} setSeverity={setSeverity2} setSnackOpen={setSnackOpen2} reload={reload} setReload={setReload} />
            <Backdrop open={open} className={classes.backdrop} />
            <SpeedDial
                ariaLabel="SpeedDial example"
                className={classes.speedDial}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                direction={direction}
            >
                <SpeedDialAction
                    key="csv"
                    icon={<InsertDriveFileIcon />}
                    tooltipTitle="ZIP"
                    tooltipOpen
                    onClick={() => toggleFileDialog(true)}
                />
                {/* <SpeedDialAction
          key="manual"
          icon={<CreateIcon />}
          tooltipTitle="MANUAL"
          tooltipOpen
          onClick={() => toggleInputDialog(true)}
        /> */}
            </SpeedDial>
            <Snackbar open={snackOpen2} autoHideDuration={6000} onClose={snackbarClose2} >
                <Alert onClose={snackbarClose2} severity={severity2}>
                {uploadResponse}
                </Alert>
            </Snackbar>
            <Snackbar open={snackOpen} autoHideDuration={6000} onClose={snackbarClose} >
                <Alert onClose={snackbarClose} severity={severity}>
                    {approverResponse}
                </Alert>
            </Snackbar>
        </Box>
    );
}