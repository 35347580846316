import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import {
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  makeStyles,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import MomentUtils from "@date-io/moment";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InputDialog = ({ open, toggle }) => {
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [selectedDate, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [seinList, setSeinList] = useState();
  const [selectedSein, setSein] = useState();
  const [supplierList, setSupplierList] = useState();
  const [selectedSupplier, setSupplier] = useState();
  const [isDisabled, setDisabled] = useState([]);
  const [initialData, getInitialData] = useState();
  const classes = useStyles();
  const showDatePicker = () => {
    isDatePickerVisible
      ? setDatePickerVisibility(false)
      : setDatePickerVisibility(true);
  };

  const handleClose = () => {
    setDatePickerVisibility(false);
  };

  const handleDateChange = (date) => {
    handleClose();
    // props.setFieldValue('start_date', date.toISOString().split('T')[0]);
    setDate(date);
  };
  const getSein = async () => {
    const customer = localStorage.getItem("userCust");
    const response = await fetch(
      `https://etd-means.aboitizpower.com/means/getSein/${customer}`
    );
    const data = await response.json();
    
    setSeinList(data);
  };

  const getSupplier = async () => {
    const response = await fetch(
      `https://etd-means.aboitizpower.com/means/getSuppliers/${selectedSein}`
    );
    const data = await response.json();
    
    setSupplierList(data);
  };
  const getData = async () => {
    const customer = localStorage.getItem("userCust");
    
    
    const today = new Date(Date.now());
    const hour = parseInt(moment(new Date()).format("H")) + 1;
    getInitialData();
    if (
      moment(selectedDate).format("YYYY-MM-DD") ==
      moment(today).format("YYYY-MM-DD")
    ) {
      setDisabled(Array(hour).fill("true"));
      
    } else if (
      moment(selectedDate).format("YYYY-MM-DD") <
      moment(today).format("YYYY-MM-DD")
    ) {
      setDisabled(Array(24).fill("true"));
      
    } else {
      setDisabled([]);
    }
    
    const response = await fetch(
      `https://etd-means.aboitizpower.com/means/getLatest/${customer}/${selectedDate}/${selectedSein}/${selectedSupplier}`
    );
    const data = await response.json();
    if (data.message == "no data") {
      getInitialData({
        sein: "",
        start_date: "",
        int_1: "",
        int_2: "",
        int_3: "",
        int_4: "",
        int_5: "",
        int_6: "",
        int_7: "",
        int_8: "",
        int_9: "",
        int_10: "",
        int_11: "",
        int_12: "",
        int_13: "",
        int_14: "",
        int_15: "",
        int_16: "",
        int_17: "",
        int_18: "",
        int_19: "",
        int_20: "",
        int_21: "",
        int_22: "",
        int_23: "",
        int_24: "",
      });
    } else {
      getInitialData(data);
    }
  };
  useEffect(() => {
    
    
    if (selectedSein) {
      getInitialData();
      setSupplier();
      setSupplierList();
      getSupplier();
    }
  }, [selectedSein]);
  useEffect(() => {
    if (selectedSupplier) {
      getData();
    }
  }, [selectedSupplier]);
  useEffect(() => {
    if (selectedDate) {
      if (selectedSupplier) {
        getData();
      } else {
        setSein();
        getSein();
      }
    }
  }, [selectedDate]);
  useEffect(() => {
    
  }, [initialData]);

  const submitForm = async (form) => {
    const customer = localStorage.getItem("userCust");
    const user_id = localStorage.getItem("userId");
    const user_role = localStorage.getItem("userRole");
    const status = user_role == 1 ? "pending" : "approved";
    const finalData = {
      ...form,
      user_id,
      sein: selectedSein,
      supplier: selectedSupplier,
      customer,
      status,
      start_date: selectedDate,
    };
    
    const access_token = localStorage.getItem("access_token");
    
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
      body: JSON.stringify(finalData),
    };
    const response = await fetch(
      "https://etd-means.aboitizpower.com/means/submitForm",
      config
    );
    const data = await response.json();
    alert(data.response);
  };
  const handleInputDialogClose = () => {
    getInitialData(); 
    setSein();
    setSupplierList();
    setSupplier();
    toggle(false);
  }
  const submitSchema = yup.object({
    int_1: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_2: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_3: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_4: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_5: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_6: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_7: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_8: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_9: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_10: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_11: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_12: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_13: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_14: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_15: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_16: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_17: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_18: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_19: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_20: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_21: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_22: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_23: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
    int_24: yup
      .string()
      .matches(/^[0-9]*$/, "Must be a number")
      .required("Required"),
  });
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleInputDialogClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4" color="secondary">
            Manual
          </Typography>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>Fill up</DialogContentText>
          <Box
            display="flex"
            flexDirection="column"
            p={0}
            m={4}
            alignItems="left"
            justifyContent="space-around"
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <FormControl className={classes.formControl}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Start Date"
                  format="MM/DD/YYYY"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </FormControl>
            </MuiPickersUtilsProvider>
            {seinList ? (
              <FormControl className={classes.formControl}>
                <Select
                  value={selectedSein}
                  onChange={(e) => setSein(e.target.value)}
                >
                  <MenuItem>Select SEIN </MenuItem>
                  {seinList.map((doc) => (
                    <MenuItem value={doc.sein}>{doc.sein}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
            {supplierList ? (
              <FormControl className={classes.formControl}>
                <Select
                  value={selectedSupplier}
                  onChange={(e) => setSupplier(e.target.value)}
                >
                  <MenuItem>Select Supplier </MenuItem>
                  {supplierList.map((doc) => (
                    <MenuItem value={doc.supplier}>{doc.supplier}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Box>

          {initialData ? (
            <Formik
              enableReinitialize
              initialValues={initialData}
              validationSchema={submitSchema}
              onSubmit={(values, actions) => {
                actions.resetForm();
                submitForm(values);
              }}
            >
              {props => (
                <Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={0}
                    m={4}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Box pr={2} pl={2}>
                      <Typography>MW</Typography>
                    </Box>
                    <Box pr={2} pl={2}>
                      <Typography>MW</Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={0}
                    m={4}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="1"
                        value={String(props.values.int_1)}
                        onChange={props.handleChange("int_1")}
                        mode="outlined"
                        error={props.errors.int_1}
                        disabled={isDisabled[0]}
                        onBlur={props.handleBlur("int_1")}
                      />
                      <Typography>
                        {props.touched.int_1 && props.errors.int_1}
                      </Typography>
                    </Box>
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="13"
                        value={String(props.values.int_13)}
                        onChange={props.handleChange("int_13")}
                        mode="outlined"
                        error={props.errors.int_13}
                        disabled={isDisabled[12]}
                        onBlur={props.handleBlur("int_13")}
                      />
                      <Typography>
                        {props.touched.int_13 && props.errors.int_13}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={0}
                    m={4}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="2"
                        value={String(props.values.int_2)}
                        onChange={props.handleChange("int_2")}
                        mode="outlined"
                        error={props.errors.int_2}
                        disabled={isDisabled[1]}
                        onBlur={props.handleBlur("int_2")}
                      />
                      <Typography>
                        {props.touched.int_2 && props.errors.int_2}
                      </Typography>
                    </Box>
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="14"
                        value={String(props.values.int_14)}
                        onChange={props.handleChange("int_14")}
                        mode="outlined"
                        error={props.errors.int_14}
                        disabled={isDisabled[13]}
                        onBlur={props.handleBlur("int_14")}
                      />
                      <Typography>
                        {props.touched.int_14 && props.errors.int_14}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={0}
                    m={4}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="3"
                        value={String(props.values.int_3)}
                        onChange={props.handleChange("int_3")}
                        mode="outlined"
                        error={props.errors.int_3}
                        disabled={isDisabled[2]}
                        onBlur={props.handleBlur("int_3")}
                      />
                      <Typography>
                        {props.touched.int_3 && props.errors.int_3}
                      </Typography>
                    </Box>
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="15"
                        value={String(props.values.int_15)}
                        onChange={props.handleChange("int_15")}
                        mode="outlined"
                        error={props.errors.int_15}
                        disabled={isDisabled[14]}
                        onBlur={props.handleBlur("int_15")}
                      />
                      <Typography>
                        {props.touched.int_15 && props.errors.int_15}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={0}
                    m={4}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="4"
                        value={String(props.values.int_4)}
                        onChange={props.handleChange("int_4")}
                        mode="outlined"
                        error={props.errors.int_4}
                        disabled={isDisabled[3]}
                        onBlur={props.handleBlur("int_4")}
                      />
                      <Typography>
                        {props.touched.int_4 && props.errors.int_4}
                      </Typography>
                    </Box>
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="16"
                        value={String(props.values.int_16)}
                        onChange={props.handleChange("int_16")}
                        mode="outlined"
                        error={props.errors.int_16}
                        disabled={isDisabled[15]}
                        onBlur={props.handleBlur("int_16")}
                      />
                      <Typography>
                        {props.touched.int_16 && props.errors.int_16}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={0}
                    m={4}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="5"
                        value={String(props.values.int_5)}
                        onChange={props.handleChange("int_5")}
                        mode="outlined"
                        error={props.errors.int_5}
                        disabled={isDisabled[4]}
                        onBlur={props.handleBlur("int_5")}
                      />
                      <Typography>
                        {props.touched.int_5 && props.errors.int_5}
                      </Typography>
                    </Box>
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="17"
                        value={String(props.values.int_17)}
                        onChange={props.handleChange("int_17")}
                        mode="outlined"
                        error={props.errors.int_17}
                        disabled={isDisabled[16]}
                        onBlur={props.handleBlur("int_17")}
                      />
                      <Typography>
                        {props.touched.int_17 && props.errors.int_17}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={0}
                    m={4}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="6"
                        value={String(props.values.int_6)}
                        onChange={props.handleChange("int_6")}
                        mode="outlined"
                        error={props.errors.int_6}
                        disabled={isDisabled[5]}
                        onBlur={props.handleBlur("int_6")}
                      />
                      <Typography>
                        {props.touched.int_6 && props.errors.int_6}
                      </Typography>
                    </Box>
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="18"
                        value={String(props.values.int_18)}
                        onChange={props.handleChange("int_18")}
                        mode="outlined"
                        error={props.errors.int_18}
                        disabled={isDisabled[17]}
                        onBlur={props.handleBlur("int_18")}
                      />
                      <Typography>
                        {props.touched.int_18 && props.errors.int_18}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={0}
                    m={4}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="7"
                        value={String(props.values.int_7)}
                        onChange={props.handleChange("int_7")}
                        mode="outlined"
                        error={props.errors.int_7}
                        disabled={isDisabled[6]}
                        onBlur={props.handleBlur("int_7")}
                      />
                      <Typography>
                        {props.touched.int_7 && props.errors.int_7}
                      </Typography>
                    </Box>
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="19"
                        value={String(props.values.int_19)}
                        onChange={props.handleChange("int_19")}
                        mode="outlined"
                        error={props.errors.int_19}
                        disabled={isDisabled[18]}
                        onBlur={props.handleBlur("int_19")}
                      />
                      <Typography>
                        {props.touched.int_19 && props.errors.int_19}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={0}
                    m={4}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="8"
                        value={String(props.values.int_8)}
                        onChange={props.handleChange("int_8")}
                        mode="outlined"
                        error={props.errors.int_8}
                        disabled={isDisabled[7]}
                        onBlur={props.handleBlur("int_8")}
                      />
                      <Typography>
                        {props.touched.int_8 && props.errors.int_8}
                      </Typography>
                    </Box>
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="20"
                        value={String(props.values.int_20)}
                        onChange={props.handleChange("int_20")}
                        mode="outlined"
                        error={props.errors.int_20}
                        disabled={isDisabled[19]}
                        onBlur={props.handleBlur("int_20")}
                      />
                      <Typography>
                        {props.touched.int_20 && props.errors.int_20}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={0}
                    m={4}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="9"
                        value={String(props.values.int_9)}
                        onChange={props.handleChange("int_9")}
                        mode="outlined"
                        error={props.errors.int_9}
                        disabled={isDisabled[8]}
                        onBlur={props.handleBlur("int_9")}
                      />
                      <Typography>
                        {props.touched.int_9 && props.errors.int_9}
                      </Typography>
                    </Box>
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="21"
                        value={String(props.values.int_21)}
                        onChange={props.handleChange("int_21")}
                        mode="outlined"
                        error={props.errors.int_21}
                        disabled={isDisabled[20]}
                        onBlur={props.handleBlur("int_21")}
                      />
                      <Typography>
                        {props.touched.int_21 && props.errors.int_21}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={0}
                    m={4}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="10"
                        value={String(props.values.int_10)}
                        onChange={props.handleChange("int_10")}
                        mode="outlined"
                        error={props.errors.int_10}
                        disabled={isDisabled[9]}
                        onBlur={props.handleBlur("int_10")}
                      />
                      <Typography>
                        {props.touched.int_10 && props.errors.int_10}
                      </Typography>
                    </Box>
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="22"
                        value={String(props.values.int_22)}
                        onChange={props.handleChange("int_22")}
                        mode="outlined"
                        error={props.errors.int_22}
                        disabled={isDisabled[21]}
                        onBlur={props.handleBlur("int_22")}
                      />
                      <Typography>
                        {props.touched.int_22 && props.errors.int_22}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={0}
                    m={4}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="11"
                        value={String(props.values.int_11)}
                        onChange={props.handleChange("int_11")}
                        mode="outlined"
                        error={props.errors.int_11}
                        disabled={isDisabled[10]}
                        onBlur={props.handleBlur("int_11")}
                      />
                      <Typography>
                        {props.touched.int_11 && props.errors.int_11}
                      </Typography>
                    </Box>
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="23"
                        value={String(props.values.int_23)}
                        onChange={props.handleChange("int_23")}
                        mode="outlined"
                        error={props.errors.int_23}
                        disabled={isDisabled[22]}
                        onBlur={props.handleBlur("int_23")}
                      />
                      <Typography>
                        {props.touched.int_23 && props.errors.int_23}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={0}
                    m={4}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="12"
                        value={String(props.values.int_12)}
                        onChange={props.handleChange("int_12")}
                        mode="outlined"
                        error={props.errors.int_12}
                        disabled={isDisabled[11]}
                        onBlur={props.handleBlur("int_12")}
                      />
                      <Typography>
                        {props.touched.int_12 && props.errors.int_12}
                      </Typography>
                    </Box>
                    <Box pr={2} pl={2}>
                      <TextField
                       
                        label="24"
                        value={String(props.values.int_24)}
                        onChange={props.handleChange("int_24")}
                        mode="outlined"
                        error={props.errors.int_24}
                        disabled={isDisabled[23]}
                        onBlur={props.handleBlur("int_24")}
                      />
                      <Typography>
                        {props.touched.int_24 && props.errors.int_24}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={0}
                    m={4}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                  <Button
                    icon="send"
                    disabled={isDisabled[23]}
                    variant="contained"
                    color="primary" 
                    title="submit"
                    onClick={props.handleSubmit}
                  >
                    SUBMIT
                  </Button>
                  </Box>
                </Box>
              )}
            </Formik>
          ) : selectedSupplier ? (
            <h1>loading</h1>
          ) : null}
        </DialogContent> 
      </Dialog>
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default InputDialog;
