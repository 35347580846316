import React, { useEffect, useState } from 'react';
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { forwardRef } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Download from '@material-ui/icons/GetApp';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
var fileDownload = require('js-file-download');
const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
const useStyles = makeStyles((theme) => ({
    Paper: {
        padding: 20,
        margin: theme.spacing(8),
        flex: 1,
        alignItems: "center"
    },
    Table: {
        borderRadius: 10, 
        overflowX: 'auto'
    },
    Table2: { 
        padding: 30,
        overflowX: 'auto'
    }
}));
export default function DashboardTable() {
    const [tableData, getTableData] = useState([]);
    const [columnData, getColumnData] = useState([]);
    const classes = useStyles();
    const getLatestToday = async () => {
        let customer = localStorage.getItem('userCust');
        const access_token = localStorage.getItem('access_token');
        const config = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({customer}) 
        }

        const response = await fetch(`https://etd-means.aboitizpower.com/means/getLatestToday`, config);
        const data = await response.json();
        
        getColumnData(data.column);
        getTableData(data.response);
    }
    const downloadFile = async (file_path, log_id) => {
        let file_name = file_path.split("/").pop(); 
        const access_token = localStorage.getItem("access_token"); 
        
        const config = {
          method: "POST",
          headers: {
            Authorization: "Bearer " + access_token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({file_id: log_id}), 
        };
    
        const response = await fetch(`https://etd-means.aboitizpower.com/means/download`, config);
        const data = await response.text();
        fileDownload(data, file_name);
      };  
    useEffect(() => {
        getLatestToday();
          const interval = setInterval(() => {
            getLatestToday();
          }, 10000);
          return () => {
     
            clearInterval(interval);
          }
     
    
    
      },[])
    return (
        <TableContainer component={Paper} className={classes.Table}>
            <MaterialTable  
                icons={tableIcons}
                columns={columnData}
                data={tableData}
                components={{
                    Container: (props) => <Paper className={classes.Table2} {...props}/>
                        }}
                title={<Typography variant="h4" color="secondary"><b>History of Submissions</b></Typography>}
                actions={[
                    {
                      icon: () => <Download />,
                      tooltip: 'Download',
                      iconProps: { style: { textAlign: "center", } },
                      onClick: (event, rowData) => {
                        downloadFile(rowData.file_name, rowData.idsubmissions)
                      }
                    }
                  ]}
                options={{ 
                    headerStyle: { 
                        textAlign: "middle",
                        height: 10,
                        fontWeight: 900,
                    },
                    cellStyle: {    
                        textAlign: "middle",
                        height: 10,
                        fontWeight: 400,
                    },
                    actionsCellStyle:{ 
                        textAlign: "middle",
                        height: 10,
                        fontWeight: 400,
                    },
                    actionsColumnIndex: -1
                    
                }}

            />
        </TableContainer>
    );
}