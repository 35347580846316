import React , {useState}from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function RejectDialog({open, toggle, log_id, log_file, setSubmit, isSubmit, setSnackOpen, setSeverity, setApproverResponse, setReload}, reload) {
    const [note, setNote] = useState('');
    const rejectSched = async () => {
        const customer = localStorage.getItem('userCust');
        const access_token = localStorage.getItem('access_token');
        toggle(false);
        const config = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+access_token 
          },
          body: JSON.stringify({note, schedId: log_id, customer, user_id: localStorage.getItem('userId') }),
        };
        const response = await fetch(
          'https://etd-means.aboitizpower.com/means/reject', config );
        const data = await response.json();
        if (data.message == "Transaction Completed") {
          
          setApproverResponse("Success");
          setSeverity("success");
          setSnackOpen(true);
          setReload(!reload);
          toggle(false);
        }else {
          setApproverResponse("Failed");
          setSeverity("error");
          setSnackOpen(true);
          setReload(!reload);
          toggle(false);
        }
        setSubmit(!isSubmit);
      };

  return (
    <> 
      <Dialog
        open={open}
        onClose={() => toggle(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Reject {log_file} ?
          </DialogContentText>
          <TextField
            autoFocus 
            id="note"
            label="Add Note"
            type="text"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggle(false)} color="primary">
            No
          </Button>
          <Button onClick={rejectSched} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}