import React from 'react' 
import { Box, Typography, Tab, Tabs } from '@material-ui/core';
import PropTypes from "prop-types";
import SubmissionsScreen from './SubmissionsScreen';
import AcknowledgedScreen from './AcknowledgedScreen';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
const BCQPortal = () => {
    const [value, setValue] = React.useState(0);
    function handleChange(event, newValue) {
        setValue(newValue);
      }
    function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
    }
  return (
    <Box>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="PENDING"  {...a11yProps(0)}/>
          <Tab label="CONSOLIDATED"  {...a11yProps(1)}/>
        </Tabs>

        <TabPanel value={value} index={0}>
            <SubmissionsScreen />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AcknowledgedScreen />
        </TabPanel>
    </Box>
  )
}

export default BCQPortal