import React, { useEffect, useState } from 'react';
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { forwardRef } from 'react';
import TableContainer from '@material-ui/core/TableContainer'; 
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from "@material-ui/lab/Alert";
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField'; 
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Backdrop from '@material-ui/core/Backdrop'; 
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card'; 
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file 
import Download from '@material-ui/icons/GetApp';
import FileDialog from "../dialogs/FileUploadBCQDialog";
import DatePicker from "react-datepicker";  
import "react-datepicker/dist/react-datepicker.css"; 
var fileDownload = require('js-file-download');

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
const useStyles = makeStyles((theme) => ({
    Paper: {
        padding: 20,
        margin: theme.spacing(8),
        flex: 1,
        alignItems: "center",
    },
    Table: {
        borderRadius: 10,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "yellow"
    },
    speedDial: {
        margin: theme.spacing.unit,
        position: "fixed",
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 3
      },
    backdrop: {
    zIndex: 100,
    color: "#fff",
  },
    formControl: {
        margin: theme.spacing(1),
        fontSize: 10,
        minWidth: 300,
        maxWidth: 350
    }
}));
export default function SubmissionsScreen() {
    const [isFileDialogOpen, toggleFileDialog] = useState(false);
    const [tableData, getTableData] = useState([]);
    const [columnData, getColumnData] = useState([]);
    const [logId, setLogId] = useState('');
    const [fileName, setFileName] = useState('');
    const [open, setOpen] = useState(false);
    const [appDisabled, setAppDisabled] = useState(false);
    const [type, setType] = useState(false);
    const classes = useStyles();
    const [regions, setRegions] = useState();
    const [customers, setCustomers] = useState();
    const [selectedRegion, setSelectedRegion] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [severity, setSeverity] = useState('');
    const [severity2, setSeverity2] = useState('');
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackOpen2, setSnackOpen2] = React.useState(false);
    const [isSubmit, setSubmit] = useState(false);
    const [direction, setDirection] = React.useState("up");
    const [approverResponse, setApproverResponse] = useState('');
    const [uploadResponse, setUploadResponse] = useState('');
    const [reload, setReload] = useState(false);
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [startDate, endDate] = dateRange;
    const snackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackOpen(false);
    };
    const snackbarClose2 = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackOpen2(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const getLatestToday = async () => {
        const access_token = localStorage.getItem('access_token');
        const config = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            }
        }

        const response = await fetch(`https://etd-means.aboitizpower.com/means/getLatestTodayBCQ_n`, config);
        const data = await response.json();

        getColumnData(data.column);
        getTableData(data.response);
    }
    const loadData = async () => {
        const access_token = localStorage.getItem('access_token');
        const config = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ customer: selectedCustomer, date_range: dateRange })
        }

        const response = await fetch(`https://etd-means.aboitizpower.com/means/getSubmissions_n`, config);
        const data = await response.json();

        getColumnData(data.column);
        getTableData(data.response);
    }
    const getRegions = async () => {
        const access_token = localStorage.getItem('access_token');
        const config = {
            headers: {
                'Authorization': 'Bearer ' + access_token,
            }
        }
        const response = await fetch(`https://etd-means.aboitizpower.com/means/regionsList`, config);
        const data = await response.json();
        console.log(data)
        setRegions(data.data)
    }
    const getCustomers = async () => {
        const access_token = localStorage.getItem('access_token');
        const config = {
            headers: {
                'Authorization': 'Bearer ' + access_token,
            }
        }
        const response = await fetch(`https://etd-means.aboitizpower.com/means/customersListReg/${selectedRegion}`, config);
        const data = await response.json();
        setCustomers(data.data)
    }
    useEffect(() => {

        // const interval = setInterval(() => {
        //     getLatestToday();
        // }, 10000);
        // return () => {

        //     clearInterval(interval);
        // }
        getLatestToday();


    }, [isSubmit])
    const acknowledgeSched = async (rowData) => {
        const access_token = localStorage.getItem('access_token');
        const config = {
            method: "POST",
            headers: {
                Authorization: "Bearer " + access_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ schedId: rowData.idsubmissions }),
        };
        const response = await fetch(
            'https://etd-means.aboitizpower.com/means/acknowledge_n', config
        );
        const data = await response.json();
        if (data.message == "Transaction Completed") {

            setApproverResponse("Success");
            setSeverity("success");
            setSnackOpen(true);
            setAppDisabled(false)
        } else {
            setApproverResponse("Failed");
            setSeverity("error");
            setSnackOpen(true);
            setAppDisabled(false)
        }
        setAppDisabled(false)
        // setSubmit(!isSubmit);
    };
    const downloadFile = async () => {
        if (logId) {
            const access_token = localStorage.getItem("access_token");
            const config = {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + access_token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ file_id: logId }),
            };

            const response = await fetch(`https://etd-means.aboitizpower.com/means/download`, config);
            const data = await response.text();

            fileDownload(data, fileName);
            setOpen(false);
        }
    };
    const handleDownload = async (v) => {
        setOpen(true);
        setLogId(v.idsubmissions);
        setFileName(v.file_name)
    }
    useEffect(() => {
        if (!isNaN(logId)) {
            downloadFile();
        }
    }, [logId])

    useEffect(() => {
        getRegions();
    }, [dateRange])
    useEffect(() => {
        getCustomers();
    }, [selectedRegion])
    useEffect(() => {
        // if(startDate==null){
        //     setDateRange([moment(new Date(), "YYYY/MM/DD HH:mm:ss"), moment(new Date(), "YYYY/MM/DD HH:mm:ss")])
        // }
        // else{
        //     console.log(moment(new Date(), "YYYY/MM/DD HH:mm:ss"))
            loadData()
        // }
       console.log(dateRange)
        getColumnData();
        getTableData(); 
    }, [selectedCustomer, dateRange])
    return (
        <Box>
        <Box m={1} display="flex" alignItems="flex-start" flexDirection="column" flexWrap="wrap"  >
                        <Card>
                        <Box m={1} component={Paper} display="flex" alignItems="center" justifyContent="center" flexDirection="row">
                            
                            <Box>
                                <FormControl variant="outlined" > 
                                <DatePicker
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat="yyyy/MM/dd"
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    customInput={<TextField variant='outlined' label="Date Range" margin="dense" className={classes.formControl}/>}
                                    withPortal
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl margin="dense" variant="outlined" className={classes.formControl}>
                                    <InputLabel>REGION</InputLabel>
                                    <Select
                                        value={selectedRegion}
                                        onChange={(e) => setSelectedRegion(e.target.value)}
                                        label="CUSTOMER"
                                    >
                                        {
                                            regions ? regions.map(x => (
                                                <MenuItem value={x.idregions}>{x.region_name}</MenuItem>
                                            )

                                            ) : null
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl margin="dense" variant="outlined" className={classes.formControl}>
                                    <InputLabel>CUSTOMER</InputLabel>
                                    <Select
                                        value={selectedCustomer}
                                        onChange={(e) => setSelectedCustomer(e.target.value)}
                                        label="CUSTOMER"
                                    >
                                        {
                                            customers ? customers.map(x => (
                                                <MenuItem value={x.idcustomers}>{x.customer_name}</MenuItem>
                                            )

                                            ) : null
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box m={1} >
                            <TableContainer component={Table} className={classes.Table}>
                                
                                        <MaterialTable
                                            icons={tableIcons}
                                            columns={columnData}
                                            data={tableData}
                                            title=""
                                            actions={[
                                                {
                                                    icon: () => <Download />,
                                                    tooltip: 'Download',
                                                    disabled: appDisabled,
                                                    iconProps: { style: { textAlign: "center", } },
                                                    onClick: (event, rowData) => {
                                                        handleDownload(rowData)
                                                    }
                                                },
                                                rowData => ({
                                                    icon: () => <Check />,
                                                    tooltip: 'Acknowledge',
                                                    iconProps: { style: { textAlign: "center", } },
                                                    onClick: (event, rowData) =>{
                                                        
                                                        if(window.confirm("You want to acknowledge? ID: " + rowData.idsubmissions)){
                                                            setAppDisabled(true)
                                                            acknowledgeSched(rowData)
                                                        }
                                                    },
                                                    disabled:  rowData.acknowledgement == "OK",
                                                })
                                                
                                            ]}
                                            options={{ 
                                                pageSize:12, 
                                                actionsColumnIndex: -1,
                                                actionsCellStyle: {
                                                    textAlign: "middle",
                                                    height: 10,
                                                    fontWeight: 400,
                                                },
                                                padding: "dense", 
                                                headerStyle: {
                                                    textAlign: "middle", 
                                                    fontWeight: 900,
                                                },
                                                cellStyle: {
                                                    textAlign: "middle", 
                                                    fontSize: 13,
                                                    fontWeight: 300,
                                                },  
                                            }}

                                        />
                                     
                                
                            </TableContainer>
                        </Box> 
 
            </Card>
            <FileDialog open={isFileDialogOpen} toggle={toggleFileDialog} setUploadResponse={setUploadResponse} setSeverity={setSeverity2} setSnackOpen={setSnackOpen2} reload={reload} setReload={setReload} />
            <Backdrop open={open} className={classes.backdrop} /> 
            <Snackbar open={snackOpen2} autoHideDuration={6000} onClose={snackbarClose2} >
                <Alert onClose={snackbarClose2} severity={severity2}>
                {uploadResponse}
                </Alert>
            </Snackbar>
            <Snackbar open={snackOpen} autoHideDuration={6000} onClose={snackbarClose} >
                <Alert onClose={snackbarClose} severity={severity}>
                    {approverResponse}
                </Alert>
            </Snackbar>
        </Box>
        </Box>
    );
}