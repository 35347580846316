import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard({ 
  date_time,
  user_name,
  log_file,
  status,
  note,
}) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography>{moment(date_time).format("YYYY-MM-DD HH:mm:ss")}</Typography>

        <Typography>User: {user_name}</Typography>
        <Typography>
          File:{log_file ? log_file.split("/").pop() : "manual"}
        </Typography>
        <Typography>Status: {status.toUpperCase()}</Typography>
        {status == "rejected" ? (
          <Typography>Note: {note}</Typography>
        ) : null}
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
  );
}
