// src/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { firebaseAuth } from './Firebase'; 
const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true); 
  useEffect(() => {
    const unsubscribe = firebaseAuth().onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      setLoading(false);
      
      // console.log(user) 
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}