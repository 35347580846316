import React, { useState, useEffect, useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Box, Button, Backdrop } from "@material-ui/core";
import clsx from "clsx";  
import GoogleButton from 'react-google-button' 
import { useAuth } from './../Auth';
import { firebaseAuth, googleProvider } from '../Firebase';
import { useHistory } from 'react-router-dom'; // Import useHistory
import { useVerification } from '../VerificationContext';
const backgroundImage = require('../img/bg3.jpg');
const backgroundLoginImage = require('../img/logobg.jpg');
const logo = require('../img/logo.png');
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  Paper: {
    padding: theme.spacing(5),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  large: {
    width: 250, height: 154
  },
  root: {
    height: '100vh',
  },
  bg: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: '95% 130%'
  },
  logobg: {
    backgroundImage: `url(${backgroundLoginImage})`,
    backgroundSize: 'cover',
    borderRadius: '5px'
  }
}));

function GoogleSSOLogin() {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const history = useHistory(); // Create a history object
  const { isEmailVerified, setIsEmailVerified } = useVerification();
  const handleGenOTP = async(email) => {
    
    const config = {
      method: "POST",
      headers: { 
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email }),
    };
    const response = await fetch("https://etd-means.aboitizpower.com/means/generate_otp", config);
    const data = await response.json();

    // console.log(data.msg)

  }
  const handleGoogleLogin = async () => { 
    try {
      const result = await firebaseAuth().signInWithPopup(googleProvider);
      const token = await result.user.getIdToken();
      // localStorage.setItem('access_token', token);
      // Check if the user is logged in and has 2FA enabled
      // console.log(currentUser)
      // console.log(isEmailVerified)
      if (currentUser && isEmailVerified) {
        history.push('/'); // Redirect to the dashboard
      } else {
        localStorage.setItem('userPhoto', result.user.photoURL);
        handleGenOTP(result.user.email);
        setIsEmailVerified(false); // Set email verification state to false
        history.push({
          pathname: '/otp',
          state: { email: result.user.email },
        });
      }
    } catch (error) {
      console.error('Google login failed', error);
    }
  }

  const handleLogout = async () => {
    try {
      await firebaseAuth().signOut();
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  // useEffect(async() => {
  //  if(currentUser){z
  //   const access_token = localStorage.getItem("access_token");
  //   const config = {
  //     method: "POST",
  //     headers: {
  //       Authorization: "Bearer " + access_token,
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({ email: currentUser.email }),
  //   };

  //   const response = await fetch("https://etd-means.aboitizpower.com/means/checkifLoggedIn", config);
  //   const data =  await response.json() 
  //     setIsEmailVerified(data.status); 
  //  }
  // },[])
  if (currentUser && isEmailVerified) {
    history.push('/'); // Redirect to the dashboard
  }
  return (
    <div className={classes.bg}>
      <Grid container component="main" className={classes.root}>
        <Grid item xs={false} sm={4} md={7} backgroundColor="yellow">
 
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.logobg}>
            <Box
              display="flex"
              justifyContent="center"
              justifyItems="center"
              flexDirection="column"
              alignItems="center"
              minHeight="100vh"
            >
              <Box m={1}>
                <img className={classes.large} src={logo} />

              </Box>
              <Box mt={6}>
                <Typography variant="h3" color="primary" style={{ fontFamily: "'League Spartan', sans-serif" }}><b>MEANS</b></Typography>
                </Box>
               <Box >

                <Typography variant="h6" color="primary" style={{ fontFamily: "'League Spartan', sans-serif" }}> <i>(My Easy Access Nomination System)</i></Typography>
              </Box>
              <Box m={10}>
                <GoogleButton
                  style
                  type="light" // can be light or dark
                  onClick={handleGoogleLogin}
                />
              </Box>
            </Box>
          </div>

        </Grid>
      </Grid>
    </div>
  );
}

export default GoogleSSOLogin;