import React, { useEffect, useState } from "react";
import { firebaseAuth, firebaseMessaging } from "../Firebase";
import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline, Box, AppBar, Toolbar, Button, Typography, ThemeProvider, createMuiTheme, FormControlLabel, Snackbar } from "@material-ui/core";
import AnalystScreen from "./AnalystScreen"
import { Redirect } from 'react-router-dom';
import { useAuth } from '../Auth';
import BCQPortal from "./BCQPortal"
import Switch from "@material-ui/core/Switch";
import blue from "@material-ui/core/colors/blue";
import Divider from "@material-ui/core/Divider";
import orange from "@material-ui/core/colors/orange";
import Brightness4Icon from '@material-ui/icons/Brightness4';
import NotifIcon from '@material-ui/icons/NotificationsActive';
import ApproverNewScreen from "./ApproverNewScreen";
import Alert from "@material-ui/lab/Alert";
import { useVerification } from '../VerificationContext';
const logo = require('../img/logo-1.png');
const font = "'CircularStd', sans-serif";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  smol: {
    height: 30
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  offset: theme.mixins.toolbar
}));
const refreshNotif = async () => {
  const regId = await firebaseMessaging.getToken();
  const config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ regId: regId })
  }

  const response = await fetch(`https://etd-means.aboitizpower.com/means/setRegistrationId/${localStorage.getItem('userId')}`, config)
  alert((await response.json()).message)
}
const handleGoogleLogout = () => {
  firebaseAuth().signOut();
  localStorage.clear();
};
const HomeScreen = () => {
  const role = localStorage.getItem('userRole') || ''
  const classes = useStyles();

  const [darkState, setDarkState] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [notif, setNotif] = useState('');
  useEffect(() => {

    navigator.serviceWorker.addEventListener("message", (message) => {
      setNotif(message.data["firebase-messaging-msg-data"].notification.body)
      setSnackOpen(true)
      console.log(message.data["firebase-messaging-msg-data"].notification.title)
      console.log(message.data["firebase-messaging-msg-data"].notification.body)

    });
  }, []);
  const palletType = darkState ? "dark" : "light";
  const darkTheme = createMuiTheme({
    palette: {
      type: palletType,
      primary: blue,
      secondary: blue,
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*': {
            'scrollbar-width': 'thin',
          },
          '*::-webkit-scrollbar': {
            width: '4px'
          },
          '*::-webkit-scrollbar-thumb': {
            width: '4px',
            height: '4px',
            backgroundColor: '#303030'
          }
        }
      },

    },
    typography: {
      fontFamily: "'Calibri', sans-serif",
      h1: {
        fontWeight: 500,
        fontSize: 35
      },
      h2: {
        fontWeight: 400,
        fontSize: 40
      },
      h3: {
        fontWeight: 300,
        fontSize: 15
      },
      h4: {
        fontWeight: 500,
        fontSize: 30
      }
    },
    fontFamily: font,
    status: {
      danger: "orange",
    }
  });



  const handleThemeChange = () => {
    setDarkState(!darkState);
  };

  const snackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };
  const { currentUser } = useAuth();
  const { isEmailVerified, setIsEmailVerified } = useVerification();
 useEffect(() => {
  console.log(currentUser)
  console.log(isEmailVerified)
  if (currentUser && isEmailVerified) {  
    console.log("lol")
  }

 },[currentUser, isEmailVerified])
  if (currentUser && isEmailVerified) {  
  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={snackOpen} autoHideDuration={10000} onClose={snackbarClose} >
          <Alert onClose={snackbarClose} severity={severity}>
            <h1>{notif}</h1>
          </Alert>
        </Snackbar>
        <AppBar color="white" position="static">




          <Toolbar>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-around">
              <Box>
                <img className={classes.smol} src={logo} /> 
              </Box>
              <Divider light variant="middle" orientation="vertical" flexItem />
              <Box m={1}>
                <Typography variant="h5" color="primary">MEANS</Typography> 
              </Box>
              <Box>
                <h3><i>(My Easy Access Nomination System)</i></h3>
              </Box> 
            </Box>
            <Box flexGrow={1} direction="column" justifyContent="center" alignItems="center" />
            <Button onClick={handleThemeChange}>
              <Brightness4Icon />
            </Button>
            <Button onClick={refreshNotif}>
              <NotifIcon />
            </Button>
            <Button variant="outlined" onClick={handleGoogleLogout}>Logout</Button>
          </Toolbar>
        </AppBar>

        {role == 0 ? (<ApproverNewScreen />) : (role == 5 ? <BCQPortal /> : <AnalystScreen />)}

      </ThemeProvider>

    </>
  ) }
  else {
    return <Redirect to="/login" />;
  }
};

export default HomeScreen;
 