import React, { useEffect, useState } from 'react';
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { forwardRef } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Typography from '@material-ui/core/Typography'; 
import Paper from '@material-ui/core/Paper'; 
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn'; 
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card'; 
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file  
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from '@material-ui/core'; 
import moment from 'moment';


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
const useStyles = makeStyles((theme) => ({
    Paper: {
        padding: 40, 
        flex: 1,
        alignItems: "center",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "yellow"
    },
    speedDial: {
        margin: theme.spacing.unit,
        position: "fixed",
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 3
    },
    backdrop: {
        zIndex: 100,
        color: "#fff",
    },
    Table: {
        borderRadius: 10,
        overflowX: 'auto'
    },
    formControl: {
        margin: theme.spacing(1),
        fontSize: 5,
        minWidth: 250,
        maxWidth: 350
    }
}));
export default function AcknowledgedScreen() { 
    const [tableData, getTableData] = useState([]);
    const [columnData, getColumnData] = useState([]); 
    const classes = useStyles(); 
    const [selectedBbid, setSelectedBbid] = useState();
    const [bbidList, setBbidList] = useState(); 
    const [dateRange, setDateRange] = useState(new Date());


    const getBbid = async () => {
        const access_token = localStorage.getItem('access_token');
        const config = {
            method: "POST",
            headers: {
                Authorization: "Bearer " + access_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({date_range: dateRange }),
        };
        const response = await fetch(`https://etd-means.aboitizpower.com/means/bbidc`, config);
        const data = await response.json();
        setBbidList(data.data)
    }

    const getData = async () => {
        const access_token = localStorage.getItem('access_token');
        const config = {
            method: "POST",
            headers: {
                Authorization: "Bearer " + access_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({date_range: dateRange, bbid: selectedBbid }),
        };
        const response = await fetch(`https://etd-means.aboitizpower.com/means/getConsolidatedDatac`, config);
        const data = await response.json();
        getColumnData(data.column);
        getTableData(data.response);

    }
 
    useEffect(() => {
        getBbid();
    }, [dateRange])
    useEffect(() => {
        getData();
    }, [selectedBbid, dateRange]) 

    return (
        <Card className={classes.Paper}>
            <Box>
            <Typography variant="h4" color="secondary">
                  <b>
                  {localStorage.getItem('userCust')}
                  </b>
                </Typography> 
            </Box>
            <Box component={Paper} m={2} display="flex" alignItems="center" flexDirection="row" flexWrap="wrap" >

                <Box>
                    <FormControl variant="outlined">
                        <DatePicker
                            selected={dateRange}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            customInput={<TextField variant='outlined' label="Date" margin="dense" className={classes.formControl} />}
                            withPortal
                        />
                    </FormControl>
                </Box>


                <Box>
                    <FormControl margin="dense" variant="outlined" className={classes.formControl}>
                        <InputLabel>BUYER BILLING ID.</InputLabel>
                        <Select
                            value={selectedBbid}
                            onChange={(e) => setSelectedBbid(e.target.value)}
                            label="BUYER BILLING ID."
                        >
                            {
                                bbidList ? bbidList.map(x => (
                                    <MenuItem value={x.buyer_billing_id}>{x.buyer_billing_id}</MenuItem>
                                )

                                ) : null
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Box m={3} >
                <TableContainer component={Table} className={classes.Table}>
                    {tableData.length > 0 ?
                        (<MaterialTable
                            icons={tableIcons}
                            columns={columnData}
                            data={tableData}
                            title={`${selectedBbid}_${moment(dateRange).format("YYYY-MM-DD")}`}
                            options={{
                                exportAllData: true,
                                columnsButton: true,
                                exportButton: true,
                                pageSize: 12,
                                padding: "dense",
                                headerStyle: {
                                    textAlign: "middle",
                                    fontWeight: 800,
                                },
                                cellStyle: {
                                    textAlign: "middle",
                                    fontWeight: 300,
                                },
                                actionsColumnIndex: -1
                            }}

                        />) : 'No Data'
                    }
                </TableContainer>
            </Box>
        </Card>


    );
}