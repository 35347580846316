import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../Auth';
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { firebaseAuth, firebaseMessaging, googleProvider } from "../Firebase";
import { useVerification } from '../VerificationContext';
import TimerButton from '../components/TimerButton';
import LoadingScreen from './LoadingScreen'
import red from '@material-ui/core/colors/red';
const logo = require('../img/otp.jpg');
const useStyles = makeStyles((theme) => ({
  Paper: {
    padding: 50,
    borderRadius: 10,
    maxWidth: 500
  },
  large: {
    width: "100%"
  },
  otpField: {
    width: '2.5em',
  },
  otpContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
function OTPPage(props) {
  const [isLoading, setIsLoading] = useState(true)
  const { currentUser } = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const [otp, setOTP] = useState(['', '', '', '', '']);
  const { isEmailVerified, setIsEmailVerified } = useVerification();
  const [isUserVerified, setIsUserVerified] = useState(false)
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const emailAddress = localStorage.getItem('userEmail');
  const handleGoogleLogout = () => {
    firebaseAuth().signOut();
    localStorage.clear();

  };
  const handleGenOTP = async () => {

    const config = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: props.location.state.email }),
    };
    const response = await fetch("https://etd-means.aboitizpower.com/means/generate_otp", config);
    const data = await response.json();

    // console.log(data.msg)

  }
  const handleVerifyOTP = async () => {
    const config = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: props.location.state.email, otp }),
    };

    const response = await fetch("https://etd-means.aboitizpower.com/means/verify_otp", config);
    const data = await response.json();
    // console.log(data)
    // console.log(data.msg == 'ok')
    // if(data.msg==='ok'){
    // console.log(data.msg == 'ok')

    // console.log("123454")
    localStorage.setItem('access_token', data.access_token)
    if (data.msg == 'ok') {
      // setIsLoggedIn(true)  
      // console.log("asdhjk")
      // console.log(data)

      const access_token = localStorage.getItem("access_token");
      // console.log("access", access_token)
      const config = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + access_token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: currentUser.email }),
      };

      const response = await fetch("https://etd-means.aboitizpower.com/means/getUserLoginInfo", config);
      const data = await (await response.json()).data;
      // console.log(data)
      localStorage.setItem('userName', data.user_name);
      localStorage.setItem('userEmail', data.user_email);
      localStorage.setItem('userRole', data.user_role);
      localStorage.setItem('userCust', data.user_cust);
      localStorage.setItem('userId', String(data.user_id));
      // console.log(data);
      setIsEmailVerified(true);

      // setCurrentUser(localStorage.getItem('userInfo'))  
    } else {
      handleGoogleLogout();
    }
    if (currentUser && isEmailVerified) {
      history.push('/'); // Redirect to the dashboard
    } else {
      // Handle the case where OTP is verified but email is not verified
      console.error('Email not verified');
    }

    // }


  }
  useEffect(() => {
    // Check if both currentUser and isEmailVerified are true
    if (currentUser && isEmailVerified) {
      // console.log('Redirecting to dashboard...');
      history.push('/'); // Redirect to the dashboard
    }
  }, [currentUser, isEmailVerified, history]);
  useEffect(() => {
    if (currentUser) {
      handleVerifyUser()
    }
  }, [])
  const handleVerifyUser = async () => {
    const config = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ currentUser }),
    };

    const response = await fetch("https://etd-means.aboitizpower.com/means/validate_user", config);
    const data = await response.json()
    console.log(data)
    if (data?.msg == 'ok') {
      setIsUserVerified(true)
      setIsLoading(false);

    } else {
      console.log('signout')
      setIsUserVerified(false)
      setIsLoading(false);

    }
  }
  const handleOTPChange = (event, index) => {
    const updatedOTP = [...otp];
    const inputValue = event.target.value;

    if (/^\d*$/.test(inputValue) && inputValue.length <= 1) {
      // Check if the input is a digit and has a length of 1 or less
      updatedOTP[index] = inputValue;
    } else if (inputValue === '') {
      // Allow empty input (for pasting)
      updatedOTP[index] = '';
    }

    setOTP(updatedOTP);

    if (event.target.value === '') {
      // Focus on the current input field when the current field is blank
      inputRefs[index].current.focus();
    } else if (inputValue.length === 1 && index < otp.length - 1) {
      // Focus on the next input field when entering a character
      inputRefs[index + 1].current.focus();
    }
  };

  // Handle the Backspace key press
  const handleBackspace = (event, index) => {
    if (event.key === 'Backspace') {
      const updatedOTP = [...otp];

      if (index > 0) {
        // Focus on the previous input field when in any field other than the first
        inputRefs[index - 1].current.focus();
      }

      // Delete the value in the current input field
      updatedOTP[index] = '';
      setOTP(updatedOTP);
    }
  };

  // Handle paste event
  const handlePaste = (event) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text/plain');
    const updatedOTP = [...otp];

    // Limit the pasted data to one character
    const pastedCharacters = pastedData.slice(0, otp.length);

    for (let i = 0; i < pastedCharacters.length; i++) {
      const pastedCharacter = pastedCharacters.charAt(i);

      if (/^\d*$/.test(pastedCharacter)) {
        // Check if the pasted character is a digit
        updatedOTP[i] = pastedCharacter;
      } else {
        updatedOTP[i] = '';
      }
    }

    setOTP(updatedOTP);
    focusNextEmptyInput(updatedOTP);
  };

  // Helper function to focus on the next empty input field
  const focusNextEmptyInput = (otpArray) => {
    for (let i = 0; i < otpArray.length; i++) {
      if (otpArray[i] === '') {
        inputRefs[i].current.focus();
        break;
      }
    }
  };

  if (isLoading) {
    return <LoadingScreen />
  }

  if (currentUser) {
    return (
      <div>

        <div>
          <Box display="flex"
            flexDirection="row"
            p={1}
            m={3}
            alignItems="center"
            justifyContent="center">
            <Card className={classes.Paper}  elevation={5}>
              <CardMedia
                className={classes.large}
                component="img"
                image={isUserVerified ? "/img/otp.jpg" : "/img/no.jpg"}
              />

              {isUserVerified && !isLoading ? (
                <CardContent>
                  <Box display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center">
                    <Typography gutterBottom variant="h5" component="h2">
                      An OTP has been sent to your registered email address:
                    </Typography>
                    <p><i>{props.location.state.email}</i></p>
                    <p><b>Please enter the OTP below to proceed:</b></p>
                    <div className={classes.otpContainer}>
                      <Grid container spacing={2}>
                        <Box alignItems="center">
                          <Box display="flex"
                            flexDirection="row"
                            alignItems="space-around"
                            m={1}
                            justifyContent="center">
                            {otp.map((value, index) => (
                              <Grid item xs={2} key={index}>
                                <Box m={1}>
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    type="text"
                                    className={classes.otpField}
                                    value={value}
                                    onChange={(e) => handleOTPChange(e, index)}
                                    onKeyDown={(e) => handleBackspace(e, index)}
                                    onPaste={handlePaste}
                                    inputRef={inputRefs[index]}
                                  />
                                </Box>
                              </Grid>
                            ))}
                          </Box>
                        </Box>
                      </Grid>

                    </div>
                  </Box>
                </CardContent>
              )
                : (<Box display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center">
                  <Box m={2}>
                    <p><h1><span style={{color: 'red'}}>Access Denied</span></h1></p> 
                    <p><b>User Not Authorized :</b> <i>{props.location.state.email}</i></p>
                    <p>Sorry, but you do not have the necessary permissions to access this page or perform this action. Please contact your administrator for assistance or log in with an authorized account.</p>

                  </Box>
                </Box>)}



              <Box display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent={isUserVerified ? "space-between" : "center"} >
                {isUserVerified && !isLoading ? (
                  <>
                    <Box>
                      <TimerButton onClick={handleGenOTP} />
                    </Box>
                    <Box>
                      <Button color="danger" onClick={handleGoogleLogout}>LOGOUT</Button>
                      <Button color="primary" onClick={handleVerifyOTP}>LOGIN</Button>
                    </Box>
                  </>

                ) : (
                  <Button color="secondary" variant="outlined" onClick={handleGoogleLogout}>LOGOUT</Button>
                )
                }
              </Box>
            </Card>
          </Box>
        </div>
      </div>
    );
  } else {
    return <Redirect to="/login" />;
  }
}

export default OTPPage;