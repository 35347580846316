import React, { useState, useCallback } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Paper,
  Button,
  MenuItem,
  Select
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Dropzone from "react-dropzone";
import { useDropzone } from "react-dropzone";  
import Papa from "papaparse";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FileUploadDialog = ({ open, toggle, setSeverity, setSnackOpen, setUploadResponse, reload, setReload }) => {
  const [file, setFile] = useState();

  const [csvObj, getCsvObj] = useState();


  const upNow = async () => {
    const user_role = localStorage.getItem("userRole");
    const status = user_role == 1 ? "PENDING" : "APPROVED";
    var formData = new FormData();
    var url = "https://etd-means.aboitizpower.com/means/upload_new"; // File upload web service path
    const access_token = localStorage.getItem("access_token");
    const body = {
      user_email: localStorage.getItem("userEmail"),
      user_upload_type: "csv",
      status 
    };
    formData.append("submit", JSON.stringify(body));
    formData.append("files", file);
    const config = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + access_token,
      },
      body: formData,
    };

    const response = await fetch(url, config);
    const data = await response.json();
    if (data.stat == 1) {
      
      setUploadResponse(data.response);
      setSeverity("success");
      setSnackOpen(true);
      setReload(!reload)
      toggle(false);
      setFile();
    }else {
      setUploadResponse(data.response);
      setSeverity("error");
      setSnackOpen(true);
      setReload(!reload)
      toggle(false);
      setFile();
    }
  };
  const handleFileChange = (file) => {
    const csv = require("csvtojson");
    const reader = new FileReader();
    
    reader.readAsText(file[0]);
    reader.onload = () => {
      csv({ ignoreEmpty: true })
        .fromString(reader.result)
        .then((jsonObj) => {
          getCsvObj(jsonObj);
          setFile(file[0]);
          
        })
        .catch((err) => alert(err));
    };
  };

  return (
    <Dialog
      open={open}
      onClose={() => toggle(false)}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle>CSV Upload</DialogTitle>
      <DialogContent>
        <Dropzone accept="text/csv"  onDrop={(acceptedFiles) => handleFileChange(acceptedFiles,)}>
          {({ getRootProps, getInputProps }) => (
            <Paper {...getRootProps()}>
              <input {...getInputProps()} />
              <Box
                display="flex"
                flexDirection="column"
                p={3}
                m={3}
                alignItems="center"
                justifyContent="space-around"
              >
                <CloudUploadIcon />
                <Typography>
                  {
                  file ? file.name :   <Typography>Drag 'n' drop CSV File"</Typography>
                    
                  }
                </Typography>
              </Box>
              <Typography></Typography>
            </Paper>
          )}
        </Dropzone>
        {/* <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-around"
        >
          <Select>
            <MenuItem>1</MenuItem>
            <MenuItem>2</MenuItem>
            <MenuItem>3</MenuItem>
            <MenuItem>4</MenuItem>
          </Select>
        </Box> */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-around"
        >
          <Button onClick={upNow}>Upload</Button>
        </Box>
     
      </DialogContent>
    </Dialog>
  );
};

export default FileUploadDialog;
