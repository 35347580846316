import firebase from 'firebase'

firebase.initializeApp({
    apiKey: "AIzaSyAkEF7yocrtYBduL0Yl8Oy9tMPrhNVMmSw",
    authDomain: "signin-trading.firebaseapp.com",
    databaseURL: "https://signin-trading.firebaseio.com",
    projectId: "signin-trading",
    storageBucket: "signin-trading.appspot.com",
    messagingSenderId: "536355339776",
    appId: "1:536355339776:web:24fc4897aded16db9c22e5",
    measurementId: "G-5JEKXFB7YS"
});
 
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const ref = firebase.database().ref();
export const firebaseAuth = firebase.auth;  
export const firebaseMessaging = firebase.messaging(); 
export const firebaseInstance = firebase;