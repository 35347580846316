import { Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
 


const TimerButton = ({ onClick, initialTimer = 60 }) => {
    const [disabled, setDisabled] = useState(true);
    const [timer, setTimer] = useState(initialTimer);
  
    useEffect(() => {
      let interval;
  
      if (timer > 0) {
        interval = setInterval(() => {
          setTimer(timer - 1);
        }, 1000);
      } else {
        setDisabled(false);
      }
  
      return () => clearInterval(interval);
    }, [timer]);
  
    const handleButtonClick = () => {
      if (!disabled) {
        setDisabled(true);
        setTimer(initialTimer);
  
        if (onClick) {
          onClick();
        }
      }
    };
  
    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={handleButtonClick}
        >
          {disabled ? `(${timer}) RESEND` : 'RESEND'}
        </Button>
      </div>
    );
  };
  
  export default TimerButton;